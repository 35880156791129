import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import { useToast } from '../ui/use-toast';
import LevelUpDialog from './LevelUpDialog';
import { boardApi } from '../../services/api';
import { cn } from '../../lib/utils';

const MaturityProgress = ({ boardId, accountId }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showPathDialog, setShowPathDialog] = useState(false);
  
  const { data: status } = useQuery(
    ['board-maturity', boardId],
    async () => {
      const result = await boardApi.checkMaturity(boardId);
      console.log('Maturity status in frontend:', result); // Add this line
      return result;
    },
    {
      refetchInterval: 20000
    }
  );

  const handleLevelUp = async () => {
    try {
      if (!status?.nextLevelOptions?.length) {
        toast({
          title: "Cannot Level Up",
          description: "No available upgrade paths found",
          variant: "destructive"
        });
        return;
      }

      setShowPathDialog(true);
    } catch (error) {
      console.error('Error in level up process:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to level up board",
        variant: "destructive"
      });
    }
  };

  const handlePathSelection = async (selectedPath) => {
    if (!selectedPath) return;

    try {
      const result = await boardApi.levelUp(boardId, { 
        selectedPath: {
          template: selectedPath.template,
          name: selectedPath.name,
          description: selectedPath.description,
          recommendedFor: selectedPath.recommendedFor || []
        }
      });

      setShowPathDialog(false);
      navigate(`/accounts/${accountId}/board/workload/${result._id}`);
      toast({
        title: "Workload Leveled Up",
        description: "Successfully upgraded workload maturity level"
      });
    } catch (error) {
      console.error('Error leveling up with path:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to level up board",
        variant: "destructive"
      });
    }
  };

  if (!status?.currentLevel) return null;

  const getProgressColor = (percentage) => {
    if (percentage < 33) return 'bg-red-500';
    if (percentage < 66) return 'bg-yellow-500';
    return 'bg-green-500';
  };

  return (
    <>
      <div className="space-y-4 mb-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h3 className="text-sm font-medium text-gray-900">Workload Progress</h3>
          </div>
          {status.canLevelUp && (
            <Button onClick={handleLevelUp}>Level Up Workload</Button>
          )}
        </div>

        <div className="space-y-2">
          <div className="relative w-full h-2 bg-gray-100 rounded-full">
            <div
              className={cn(
                "h-full rounded-full transition-all duration-300",
                getProgressColor(status.completionPercentage)
              )}
              style={{ width: `${status.completionPercentage || 0}%` }}
            />
          </div>
          <p className="text-sm text-gray-500">
            {status.canLevelUp ? "Ready to level up!" : status.reason}
          </p>
        </div>
      </div>

      <LevelUpDialog 
        open={showPathDialog}
        onOpenChange={setShowPathDialog}
        maturityStatus={status}
        onLevelUp={handlePathSelection}
      />
    </>
  );
};

export default MaturityProgress;