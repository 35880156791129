import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter
} from '../ui/dialog';
import { Button } from '../ui/button';
import { Progress } from '../ui/progress';
import { Card } from '../ui/card';
import { Badge } from '../ui/badge';
import { Alert, AlertDescription } from '../ui/alert';
import { AlertCircle } from 'lucide-react';

const LevelUpDialog = ({ 
  open, 
  onOpenChange, 
  maturityStatus, 
  onLevelUp 
}) => {
  const [selectedPath, setSelectedPath] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleLevelUp = async () => {
    if (!selectedPath) return;
    
    setIsLoading(true);
    try {
      await onLevelUp(selectedPath);
      onOpenChange(false);
    } catch (error) {
      console.error('Error leveling up:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!maturityStatus) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Level Up Workload</DialogTitle>
          <DialogDescription>
            Your workload has reached the required maturity level and is ready to level up.
            Choose your next maturity path below.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          {/* Show current progress */}
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>Current Level: {maturityStatus.currentLevel}</span>
              <span>Completion: {maturityStatus.completionPercentage}%</span>
            </div>
            <Progress value={maturityStatus.completionPercentage} />
          </div>

          {/* Path Options */}
          <div className="grid gap-4">
            {maturityStatus.nextLevelOptions?.map((path) => (
              <Card
                key={path.template}
                className={`p-4 cursor-pointer transition-all ${
                  selectedPath?.template === path.template
                    ? 'ring-2 ring-primary'
                    : 'hover:bg-secondary/50'
                }`}
                onClick={() => setSelectedPath(path)}
              >
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <h3 className="font-semibold">{path.name}</h3>
                    <Badge>Level {maturityStatus.currentLevel + 1}</Badge>
                  </div>
                  <p className="text-sm text-gray-600">{path.description}</p>
                  {path.recommendedFor?.length > 0 && (
                    <div className="flex flex-wrap gap-1">
                      {path.recommendedFor.map((rec) => (
                        <Badge key={rec} variant="secondary">
                          {rec}
                        </Badge>
                      ))}
                    </div>
                  )}
                </div>
              </Card>
            ))}
          </div>

          {!maturityStatus.nextLevelOptions?.length && (
            <Alert>
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                No next level options available for this workload.
              </AlertDescription>
            </Alert>
          )}
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleLevelUp}
            disabled={!selectedPath || isLoading}
          >
            {isLoading ? 'Creating...' : 'Level Up'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LevelUpDialog;