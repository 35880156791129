// src/components/AdminMenu.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FileCode, Menu, Settings, User, Shield, UserCheck } from 'lucide-react'; 
// Removed UserCircle2
import { Button } from '../components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu';

const AdminMenu = () => {
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="hover:bg-gray-100 rounded-full">
          <Menu className="h-6 w-6" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuLabel>Admin Tools</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate('/templates')}>
          <FileCode className="mr-2 h-4 w-4" />
          <span>Manage Templates</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate('/settings')}>
          <Settings className="mr-2 h-4 w-4" />
          <span>Settings</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate('/admin/activate-users')}>
          <UserCheck className="mr-2 h-4 w-4" />
          <span>Activate Users</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate('/admin/accounts')}>
          <User className="mr-2 h-4 w-4" />
          <span>Account Access</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate('/admin/roles')}>
          <Shield className="mr-2 h-4 w-4" />
          <span>Role Management</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AdminMenu;