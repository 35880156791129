export const refreshTokenSilently = async (auth) => {
  try {
    await auth.signinSilent();
    const newToken = auth.user?.access_token;
    if (newToken) {
      localStorage.setItem('token', newToken);
      return newToken;
    }
    throw new Error('Failed to get new token');
  } catch (error) {
    console.error('Silent token refresh failed:', error);
    throw error;
  }
};