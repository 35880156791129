import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { CognitoApi } from '../../services/api';

import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../components/ui/dialog';
import { useToast } from '../../components/ui/use-toast';
import { CheckCircle2, Search, UserPlus, UserX } from 'lucide-react';

const ActivateUsers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const selectedGroup = 'BelayUsers';

  // list users in BelayUser group
  const { data: groupUsers = [], error: groupUsersError, isLoading: groupUsersLoading } = useQuery(
    ['groupUsers', selectedGroup],
    async () => {
      const data = await CognitoApi.listGroupUsers(selectedGroup);
      return Array.isArray(data) ? data : [];
    }
  );

  // Fetch all users not in group for add dialog
  const { data: availableUsers = [] } = useQuery(
    ['available-users', selectedGroup],
    async () => {
      const data = await CognitoApi.listAvailableUsers(selectedGroup);
      return data;
    },
    {
      enabled: showAddDialog
    }
  );

  // Add user to group mutation
  const addUserMutation = useMutation(
    async ({ userId, groupId }) => {
      const response = await CognitoApi.addUsertoGroup(groupId, userId);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['groupUsers', selectedGroup]);
        queryClient.invalidateQueries(['availableUsers', selectedGroup]);
        toast({
          title: 'User activated successfully',
          description: 'The user has been activated.',
        });
        setShowAddDialog(false);
      },
      onError: (error) => {
        toast({
          title: 'Error activating user',
          description: error.message,
          variant: 'destructive',
        });
      },
    }
  );

  // Remove user from group mutation
  const removeUserMutation = useMutation(
    async ({ userId, groupId }) => {
      const response = await CognitoApi.removeUserFromGroup(groupId, userId);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['groupUsers', selectedGroup]);
        toast({
          title: 'User deactivated successfully',
          description: 'The user has been deactivated.',
        });
      },
      onError: (error) => {
        toast({
          title: 'Error deactivating user',
          description: error.message,
          variant: 'destructive',
        });
      },
    }
  );

  // Filter users based on search term
  const filteredGroupUsers = groupUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Add loading and error states
  if (groupUsersLoading) return <div>Loading group users...</div>;
  if (groupUsersError) return <div>Error loading group users: {groupUsersError.message}</div>;

  return (
    <div className="p-8 max-w-6xl mx-auto space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Active Users</h1>
        <Button onClick={() => setShowAddDialog(true)}>
          <UserPlus className="w-4 h-4 mr-2" />
          Activate User
        </Button>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Search className="w-4 h-4 text-gray-400" />
          <Input
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-64"
          />
        </div>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Joined</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredGroupUsers.map((user) => (
            <TableRow key={user.userId}>
              <TableCell className="font-medium">{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <div className="flex items-center">
                  <CheckCircle2 className="w-4 h-4 text-green-500 mr-2" />
                  {user.Enabled ? 'Active' : 'Inactive'}
                </div>
              </TableCell>
              <TableCell>
                {new Date(user.joined).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() =>
                    removeUserMutation.mutate({
                      userId: user.userId,
                      groupId: selectedGroup,
                    })
                  }
                  disabled={removeUserMutation.isLoading}
                >
                  <UserX className="w-4 h-4 mr-2" />
                  Deactivate
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Activate User</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <Input
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-4"
            />
            <div className="max-h-[300px] overflow-y-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {availableUsers
                    .filter((user) =>
                      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      user.email.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((user) => (
                      <TableRow key={user.userId}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          <Button
                            size="sm"
                            onClick={() => {
                              addUserMutation.mutate({
                                userId: user.userId,
                                groupId: selectedGroup,
                              });
                            }}
                            disabled={addUserMutation.isLoading}
                          >
                            Activate
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowAddDialog(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ActivateUsers;
