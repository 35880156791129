import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { CognitoApi } from '../../services/api';

import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import { useToast } from '../../components/ui/use-toast';
import { CheckCircle2, Search, UserPlus, UserX } from 'lucide-react';

const RoleManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const queryClient = useQueryClient();
  const { toast } = useToast();

  // list roles
  const { data: roles = [], error: rolesError, isLoading: rolesLoading } = useQuery(
    'roles',
    async () => {
      const data = await CognitoApi.listGroups();
      return Array.isArray(data) ? data.filter(group => group.name.startsWith('rbac_')) : [];
    }
  );

  // list users in selected role
  const { data: roleUsers = [], error: roleUsersError, isLoading: roleUsersLoading } = useQuery(
    ['roleUsers', selectedRole],
    async () => {
      if (!selectedRole) return [];
      const data = await CognitoApi.listGroupUsers(selectedRole);
      return Array.isArray(data) ? data : [];
    },
    {
      enabled: !!selectedRole,
    }
  );

  // Fetch all users not in role for add dialog
  const { data: availableUsers = [] } = useQuery(
    ['available-users', selectedRole],
    async () => {
      if (!selectedRole) return [];
      const data = await CognitoApi.listAvailableUsers(selectedRole);
      return data;
    },
    {
      enabled: !!selectedRole && showAddDialog
    }
  );

  // Add user to role mutation
  const addUserMutation = useMutation(
    async ({ userId, roleId }) => {
      const response = await CognitoApi.addUsertoGroup(roleId, userId);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roleUsers', selectedRole]);
        queryClient.invalidateQueries(['availableUsers', selectedRole]);
        toast({
          title: 'User added successfully',
          description: 'The user has been added to the role',
        });
        setShowAddDialog(false);
      },
      onError: (error) => {
        toast({
          title: 'Error adding user',
          description: error.message,
          variant: 'destructive',
        });
      },
    }
  );

  // Remove user from role mutation
  const removeUserMutation = useMutation(
    async ({ userId, roleId }) => {
      const response = await CognitoApi.removeUserFromGroup(roleId, userId);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roleUsers', selectedRole]);
        toast({
          title: 'User removed successfully',
          description: 'The user has been removed from the role',
        });
      },
      onError: (error) => {
        toast({
          title: 'Error removing user',
          description: error.message,
          variant: 'destructive',
        });
      },
    }
  );

  // Filter users based on search term
  const filteredRoleUsers = roleUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Add loading and error states
  if (rolesLoading) return <div>Loading roles...</div>;
  if (rolesError) return <div>Error loading roles: {rolesError.message}</div>;
  if (roleUsersLoading) return <div>Loading role users...</div>;
  if (roleUsersError) return <div>Error loading role users: {roleUsersError.message}</div>;

  return (
    <div className="p-8 max-w-6xl mx-auto space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Role Management</h1>
        <div className="flex space-x-4">
          <Select value={selectedRole} onValueChange={setSelectedRole}>
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder="Select a role" />
            </SelectTrigger>
            <SelectContent>
              {roles.map((role) => (
                <SelectItem key={role.name} value={role.name}>
                  {role.name.replace('rbac_', '')}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {selectedRole && (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Search className="w-4 h-4 text-gray-400" />
              <Input
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-64"
              />
            </div>
            <Button onClick={() => setShowAddDialog(true)}>
              <UserPlus className="w-4 h-4 mr-2" />
              Add User
            </Button>
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Joined</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredRoleUsers.map((user) => (
                <TableRow key={user.userId}>
                  <TableCell className="font-medium">{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <CheckCircle2 className="w-4 h-4 text-green-500 mr-2" />
                      {user.Enabled ? 'Active' : 'Inactive'}
                    </div>
                  </TableCell>
                  <TableCell>
                    {new Date(user.joined).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        removeUserMutation.mutate({
                          userId: user.userId,
                          roleId: selectedRole,
                        })
                      }
                      disabled={removeUserMutation.isLoading}
                    >
                      <UserX className="w-4 h-4 mr-2" />
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add User to Role</DialogTitle>
              </DialogHeader>
              <div className="py-4">
                <Input
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="mb-4"
                />
                <div className="max-h-[300px] overflow-y-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead>Action</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {availableUsers
                        .filter((user) =>
                          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          user.email.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((user) => (
                          <TableRow key={user.userId}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                              <Button
                                size="sm"
                                onClick={() => {
                                  addUserMutation.mutate({
                                    userId: user.userId,
                                    roleId: selectedRole,
                                  });
                                }}
                                disabled={addUserMutation.isLoading}
                              >
                                Add
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <DialogFooter>
                <Button variant="outline" onClick={() => setShowAddDialog(false)}>
                  Close
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default RoleManagement;
