// src/services/api.js
import axios from 'axios';
import { logDebug } from '../utils/logger';
import { refreshTokenSilently } from '../utils/auth';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add auth token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const currentAccountId = localStorage.getItem('currentAccountId');
  logDebug('FE Current account ID:', currentAccountId);
  if (currentAccountId) {
    config.headers['x-account-id'] = currentAccountId;
  }
  return config;
});

// Handle response errors
api.interceptors.response.use(
  async (response) => {
    // Check for auth refresh header
    if (response.headers['x-auth-refresh'] === 'true') {
      try {
        const auth = window._currentAuth;
        await refreshTokenSilently(auth);
      } catch (error) {
        console.error('Failed to refresh token after group change:', error);
      }
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    // Check for specific token refresh error
    if (
      error.response?.status === 401 && 
      error.response?.data?.error === 'TOKEN_REFRESH' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      
      try {
        // Get auth instance from the nearest provider
        const auth = window._currentAuth; // We'll set this in App.js
        const newToken = await refreshTokenSilently(auth);
        
        // Update the failed request with new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh fails, redirect to login
        localStorage.removeItem('token');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    
    return Promise.reject(error);
  }
);

// Admin APIs
export const CognitoApi = {
  listGroups: async () => {
    const response = await api.get('/admin/groups');
    return response.data;
  },

  listGroupUsers: async (groupId) => {
    const response = await api.get(`/admin/groups/${groupId}/users`);
    return response.data;
  },

  listAvailableUsers: async (groupId) => {
    const response = await api.get('/admin/users/available', { params: { groupId } });
    return response.data;
  },

  addUsertoGroup: async (groupId, userId) => {
    await api.post(`/admin/groups/${groupId}/users`, { userId });
  },

  removeUserFromGroup: async (groupId, userId) => {
    await api.delete(`/admin/groups/${groupId}/users/${userId}`);
  },

  createGroup: async (groupName) => {
    await api.post('/admin/groups', { groupName });
  },

  deleteGroup: async (groupId) => {
    const response = await api.delete(`/admin/groups/${groupId}`);
    return response.data;
  }
};

// Task APIs
export const taskApi = {
  getAllTasks: async (filters = {}) => {
    const response = await api.get('/tasks', { params: filters });
    return response.data;
  },

  getTaskById: async (id) => {
    const response = await api.get(`/tasks/${id}`);
    return response.data;
  },

  createTask: async (taskData) => {
    const response = await api.post('/tasks', taskData);
    return response.data;
  },

  updateTask: async (id, updates) => {
    // Make sure the id is converted to string if needed
    const taskId = String(id);
    const response = await api.patch(`/tasks/${taskId}`, updates);
    return {
      ...response.data,
      _id: String(response.data._id)  // Ensure ID is string
    };
    // Make sure the id is converted to string if needed
  },

  deleteTask: async (id) => {
    try {
      await api.delete(`/tasks/${id}`);
      return true; // Return something to indicate success
    } catch (error) {
      console.error('API Error deleting task:', error);
      throw error;
    }
  },

  getTaskHistory: async (id) => {
    const response = await api.get(`/tasks/${id}/history`);
    return response.data;
  }
};

// Board APIs
export const boardApi = {
  getAllBoards: async (filters = {}) => {
    
    try {
      const response = await api.get('/boards', { 
        params: {
          accountId: filters.accountId,
          type: filters.type
        }
      });
      return response.data;
    } catch (error) {
      console.error('Boards API error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      throw error;
    }
  },

  getBoardById: async (id) => {
    const response = await api.get(`/boards/${id}`);
    return response.data;
  },

  createBoard: async (boardData) => {
    const response = await api.post('/boards', boardData);
    return response.data;
  },

  updateBoard: async (id, updates) => {
    const response = await api.patch(`/boards/${id}`, updates);
    return response.data;
  },

  deleteBoard: async (id) => {
    await api.delete(`/boards/${id}`);
  },

  deleteColumn: async (boardId, columnId) => {
    await api.delete(`/boards/${boardId}/columns/${columnId}`);
  },

  updateColumnTitle: async (boardId, columnId, newTitle) => {
    logDebug('Making API call to update column title:', {
      boardId,
      columnId,
      newTitle
    });
    
    const response = await api.patch(`/boards/${boardId}/columns/${columnId}`, {
      title: newTitle
    });
    return response.data;
  },

  addTaskToBoard: async (boardId, taskId, position) => {
    const response = await api.post(`/boards/${boardId}/tasks`, { taskId, position });
    return response.data;
  },

  updateTaskPosition: async (boardId, taskId, position) => {
    const response = await api.patch(`/boards/${boardId}/tasks/${taskId}/position`, { position });
    return response.data;
  },

  removeTaskFromBoard: async (boardId, taskId) => {
    await api.delete(`/boards/${boardId}/tasks/${taskId}`);
  },

  checkMaturity: async (boardId) => {
    try {
      logDebug('Checking maturity for board:', boardId);
      const response = await api.get(`/boards/${boardId}/maturity`);
      return response.data;
    } catch (error) {
      console.error('Error checking board maturity:', error);
      throw error;
    }
  },

  levelUp: async (boardId, options = {}) => {
    try {
      logDebug('Leveling up board:', boardId, 'with options:', options);
      const response = await api.post(`/boards/${boardId}/level-up`, {
        selectedPath: options.selectedPath,
        notes: options.notes
      });
      return response.data;
    } catch (error) {
      console.error('Error leveling up board:', error);
      throw error;
    }
  }
};

// Account APIs
export const accountApi = {
  getCurrentAccount: async () => {
    const response = await api.get('/accounts/current');
    return response.data;
  },
  
  getAccounts: async () => {
    const response = await api.get('/accounts');
    return response.data;
  },

  getAccountById: async (id) => {
    if (!id) throw new Error('Account ID is required');
    const response = await api.get(`/accounts/${id}`);
    return response.data;
  },

  createAccount: async (accountData) => {
    const response = await api.post('/accounts', accountData);
    return response.data;
  },

  updateAccount: async (id, updates) => {
    const response = await api.patch(`/accounts/${id}`, updates);
    return response.data;
  },

  deleteAccount: async (id) => {
    await api.delete(`/accounts/${id}`);
  },

  // User management within accounts
  inviteUser: async (accountId, userData) => {
    const response = await api.post(`/accounts/${accountId}/users`, userData);
    return response.data;
  },

  removeUser: async (accountId, userId) => {
    await api.delete(`/accounts/${accountId}/users/${userId}`);
  },

  updateUserRole: async (accountId, userId, role) => {
    const response = await api.patch(`/accounts/${accountId}/users/${userId}`, { role });
    return response.data;
  },

  // Account settings
  getAccountSettings: async (accountId) => {
    const response = await api.get(`/accounts/${accountId}/settings`);
    return response.data;
  },

  updateAccountSettings: async (accountId, settings) => {
    const response = await api.patch(`/accounts/${accountId}/settings`, settings);
    return response.data;
  },

  // Account statistics
  getAccountStats: async (accountId) => {
    const response = await api.get(`/accounts/${accountId}/stats`);
    return response.data;
  },

  // Account activity
  getAccountActivity: async (accountId, params = {}) => {
    const response = await api.get(`/accounts/${accountId}/activity`, { params });
    return response.data;
  },

  // Switch current account
  switchAccount: async (accountId) => {
    localStorage.setItem('currentAccountId', accountId);
    window.dispatchEvent(new CustomEvent('accountChanged', { 
      detail: { accountId } 
    }));
  },

  getAccountNames: async (ids) => {
    const response = await api.post('/accounts/names', { ids });
    return response.data;
  },

  async getSubAccounts(accountId) {
    const response = await api.get(`/accounts/${accountId}/sub-accounts`);
    return response.data;
  }
};

// Business Case APIs
export const businessCaseApi = {
  getBusinessCase: async (boardId) => {
    const response = await api.get(`/boards/${boardId}/business-case`);
    return response.data;
  },

  updateBusinessCase: async (boardId, updates) => {
    const response = await api.patch(`/boards/${boardId}/business-case`, updates);
    return response.data;
  },

  deleteBusinessCase: async (boardId) => {
    await api.delete(`/boards/${boardId}/business-case`);
  }
};

// Template APIs
export const templateApi = {
  getAllTemplates: async (filters = {}) => {
    const response = await api.get('/templates', { params: filters });
    return response.data;
  },

  getTemplateById: async (id) => {
    const response = await api.get(`/templates/${id}`);
    return response.data;
  },

  createTemplate: async (templateData) => {
    const response = await api.post('/templates', {
      ...templateData,
      isActive: true // Default to active
    });
    return response.data;
  },

  updateTemplate: async (id, updates) => {
    const response = await api.patch(`/templates/${id}`, updates);
    return response.data;
  },

  deleteTemplate: async (id) => {
    await api.delete(`/templates/${id}`);
  },

  // Create a board from a template
  createBoardFromTemplate: async (templateId, boardData) => {
    if (!templateId || !boardData.accountId || !boardData.name) {
      console.error('Missing required data:', { templateId, boardData });
      throw new Error('Missing required data for board creation');
    }
    
    logDebug('Making template board request:', {
      url: `/templates/${templateId}/boards`,
      data: boardData
    });
    
    const response = await api.post(`/templates/${templateId}/boards`, boardData);
    return response.data;
  },

  // Get all boards created from a template
  getTemplateBoards: async (templateId) => {
    const response = await api.get(`/templates/${templateId}/boards`);
    return response.data;
  }
};

// Add account switching utility function
export const getCurrentAccountId = () => {
  return localStorage.getItem('currentAccountId');
};


export default api;
